import {
  defaultDescription,
  defaultTitle,
  siteUrl,
  social,
  socialLinks,
} from "data/config";
import React from "react";

export const SEO = ({
  title = defaultTitle,
  description = defaultDescription,
  location = "",
  robots = "index, follow",
  image = "assets/images/thumbnail.png",
  canonical = "",
}) => {
  return (
    <>
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta property="og:url" content={`${siteUrl}${canonical}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="fb:app_id" content={social.facebook} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={image} />
      <title>{title}</title>
      <html lang="es-AR" dir="ltr" />
      <meta name="robots" content={robots} />
      <link rel="canonical" href={`${siteUrl}${canonical}`} />
    </>
  );
};
