module.exports = {
  defaultTitle: "Empresa de Seguridad Privada Argentina | C&R Seguridad",
  author: "TheCookies.agency",
  siteUrl: "https://www.cyrseguridad.com.ar/", //Obsoleta
  url: "https://www.cyrseguridad.com.ar/",
  logo: "https://www.cyrseguridad.com.ar/logo-cyr.png",
  legalName: "C&R Seguridad Prvada SLR",
  name: "C&R Seguridad",
  defaultDescription:
    "Somos una empresa de seguridad privada integral. Contamos con servicios de vigilancia, seguridad electronica, sistemas de cctv y mucho más. Consultanos.",
  socialLinks: {
    facebook: "",
    twitter: "",
    linkedin: "https://www.linkedin.com/company/cyr-seguridad/",
    instagram: "",
    telegram: "",
    vimeo: "",
    youtube: "",
    tiktok: "",
    medium: "",
    spotify: "",
    podcast: "",
  },
  social: {
    facebook: "@",
    twitter: "@",
    linkedin: "@",
    instagram: "@",
    telegram: "@",
  },
  address: {
    streetAddress: "Sarandí 1255, C1251",
    region: "CABA",
    country: "Argentina",
    zipCode: "C1251",
  },
  contact: {
    email: "info@cyrseguridad.com",
    phone: "0810 333 8210",
    phones: {
      phone_buenosaires: { city: "Provincia Buenos Aires", number: "0810 333 8210" },
      phone_mendoza: { city: "Provincia Mendoza", number: "0810 333 8210" },
      phone_caba: { city: "CABA", number: "0810 333 8210" },
    },
  },
  foundingDate: "2010",
};
